.btn-main {
    padding: 20px 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 1.2rem;
    cursor: pointer;
    background: transparent;
    transition: .5s;
    border-radius: 2px;
    font-weight: 700;
}

.btn-main-md {
    padding: 7px 25px;
}

.btn-orange {
    border: 2px solid #ffb400;
    color: white;
}

.btn-orange-active {
    background-color: #ffb400;
}

.btn-orange:active, .btn-orange:hover, .btn-orange:focus {
    background-color: #e1a006;
    border-color: #e1a006;
    color: white;
    text-decoration: none;
}

.btn-disabled { 
    background-color: lightgray;
    color: gray;
    border-color: darkgray;
    cursor: not-allowed;
}

.btn-disabled:hover, .btn-disabled:active, .btn-disabled:focus { 
    background-color: lightgray;
    color: gray;
    border-color: darkgray;
    cursor: not-allowed;
}

@media (min-width: 500px) {
    .btn-md-inline {
        display: inline !important;
        width: auto !important;
    }
}

@media (max-width: 500px) {
    .btn-main {
        padding: 15px 15px;
        font-size: 1rem;
    }
}

@media (max-width: 375px) {
    .btn-main {
        padding: 10px 15px;
        font-size: .8rem;
    }
}
