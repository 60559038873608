.image-grid {
    margin-top: 0 !important;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: minmax(50px, auto);
}

.image-item img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.spinner {
    width: 125px !important;
}
