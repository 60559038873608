:root {
  --var-height-navbar-xl: 150px;
  --var-height-navbar-xs: 110px;
  --var-height-navbar-sticky: 80px;
  box-sizing: border-box !important;
}

@font-face {
  font-family: 'Straczynski-Italic';
  src: url('./fonts/Straczynski-Italic.ttf.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Straczynski-Italic.ttf.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/Straczynski-Italic.ttf.woff') format('woff'), /* Pretty Modern Browsers */
        url('./fonts/Straczynski-Italic.ttf.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Young';
  src:url('./fonts/Young.ttf.woff') format('woff'),
      url('./fonts/Young.ttf.svg#Young') format('svg'),
      url('./fonts/Young.ttf.eot'),
      url('./fonts/Young.ttf.eot?#iefix') format('embedded-opentype'); 
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Open Sans', sans-serif;
  background: #fbfbfb;
}

@media (min-width: 1200px) {
    .container {
      max-width: 1200px;
  }
}

@media (max-width: 1199px) {
    .container {
      max-width: 95%;
  }
}

/* Animations */
.delay-02s {
  animation-delay: 0.2s;
}

.delay-03s {
  animation-delay: 0.3s;
}

.delay-04s {
  animation-delay: 0.4s;
}

.delay-05s {
  animation-delay: 0.5s;
}

.delay-06s {
  animation-delay: 0.6s;
}

.delay-07s {
  animation-delay: 0.7s;
}

.delay-08s {
  animation-delay: 0.8s;
}

.delay-09s {
  animation-delay: 0.9s;
}

.delay-1s {
  animation-delay: 1s;
}

.delay-12s {
  animation-delay: 1.2s;
}

.fast-05s {
  animation-duration: 0.5s;
}