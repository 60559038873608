.social-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    border: 0px;
}

.social-icon-sm {
    width: 32px;
    height: 32px;   
}

.social-icon-lg {
    width: 80px;
    height: 80px;
}

.social-icon:last-child {
    margin-right: 0px;
}

.social-icon:hover {
    transform: scale(.95,.95);
}

.social-icon:active,
.social-icon:focus {
    transform: scale(.95,.95) translateX(1px) translateY(1px);
}

.social-icon img {
    width: 100% !important;
    height: 100% !important;
}

.social-icons-panel {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;  
}

.social-icons-panel .social-phone {
    margin: 0;
    padding: 0;
    color: white;
    font-weight: bold;
    font-size: .85rem;
    line-height: 1rem;
}

.social-icons-panel .social-phone:hover {
    color: orange;
    text-decoration: none;
}

.inner-border {
    border: 1.5px solid white;
    -webkit-box-shadow: inset 0px 0px 0px 1px white;
    -moz-box-shadow: inset 0px 0px 0px 1px white;
    box-shadow: inset 0px 0px 0px 1px white;
    box-sizing: border-box;
}

/* Responsive */
@media (max-width: 1024px) {
    .social-icons-panel {
        padding: 8px 20px;
    }
}

@media (max-width: 575px) {
    .social-icons-panel {
        padding: 0;
    }

    .social-icon {
        width: 32px;
        height: 32px;
    }
}