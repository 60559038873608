.cars-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}   

.cars-list {
    padding: 0;
    list-style: none;
}

.button-carousel {
    border: none;
    margin: 0 5px;
    margin-top: 25px;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-family: 'Lato' 'Sans-serif';
    padding: 5px;
    background-color: rgba(0, 0, 0, .6);
    border-radius: 50%;
    color: white;
    cursor: pointer;
}

.cars-container .spinner {
    width: 100%;
    text-align: center;
}

.cars-container .spinner img {
    max-width: 100px;
    margin-bottom: 10px;
}

.cars-container .spinner h3 {
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 1.5rem;
}

.slider-control-bottomcenter {
    margin-top: 40px !important;
}

.slider-control-topcenter div {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    margin-top: -20px;
}